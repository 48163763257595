import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, switchMap, throwError } from 'rxjs';
import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import { DialogComponent } from 'src/app/shared/dialogs';
import { AdminProject } from 'src/app/shared/models';
import { IPageable } from 'src/app/shared/models/pageable.model';
import { AlertService, ProjectService } from 'src/app/shared/services';
import { errorMessage, sanitizeQueryParams } from 'src/app/shared/utils';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  @Input() isAdmin = false;
  @Input() isLead = false;

  newProjectName = '';
  projects: AdminProject[] = [];
  itemCount = 0;
  loading = true;

  displayedColumns = ['name', 'manager', 'lead', 'engineeringManager', 'employeesCount'];

  constructor(
    private dialog: MatDialog,
    public route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.isAdmin || this.isLead) {
      this.displayedColumns.push('actions');
    }

    this.route.queryParams.subscribe((params: IPageable) => {
      this.loading = true;
      this.projectService.loadProjects(params, this.isAdmin || this.isLead).subscribe((result) => {
        this.projects = result.items;
        this.itemCount = result.totalElements;
        this.loading = false;
      });
    });
  }

  applyFilter(filters: Partial<IPageable> = {}, timestamp?: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: sanitizeQueryParams(
        { ...this.route.snapshot.queryParams, ...filters },
        timestamp,
      ),
    });
  }

  searchProject(name: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: sanitizeQueryParams({ name }),
    });
  }

  createNewProject() {
    if (!this.newProjectName.trim()) return;

    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.PROJECTS.CREATE_CONFIRMATION'),
          description: this.translate.instant('ADMIN.PROJECTS.CREATE_CONFIRMATION_DESCRIPTION', {
            projectName: this.newProjectName
          }),
          sharedButtonClass: DIALOG_BUTTONS.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.PROJECTS.CREATE_BUTTON'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return EMPTY;

          return this.projectService.createProject(this.newProjectName);
        }),
      )
      .subscribe({
        next: () => {
          this.alertService.showSuccess('ADMIN.PROJECTS.SUCCESSFULLY_ADDED');
          this.searchProject(this.newProjectName);
          this.newProjectName = '';
        },
        error: (err) => this.alertService.showError(errorMessage(err)),
      });
  }

  deleteDialog(project: AdminProject) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.PROJECTS.DELETE_CONFIRMATION'),
          description: this.translate.instant('ADMIN.PROJECTS.DELETE_CONFIRMATION_DESCRIPTION'),
          sharedButtonClass: DIALOG_BUTTONS.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.PROJECTS.DELETE_BUTTON'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return EMPTY;

          if (project.employeesCount) {
            return throwError(() => ({
              error: this.translate.instant('ADMIN.PROJECTS.NO_DELETING_NON_EMPTY_PROJECT'),
            }));
          }
          return this.projectService.deleteProjectById(project.id);
        }),
      )
      .subscribe({
        next: () => {
          this.alertService.showSuccess('ADMIN.PROJECTS.SUCCESSFULLY_REMOVED');
          this.applyFilter(this.route.snapshot.queryParams, Date.now());
        },
        error: (err) => this.alertService.showError(errorMessage(err)),
      });
  }

  archiveDialog(project: AdminProject) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.PROJECTS.ARCHIVE_CONFIRMATION'),
          description: this.translate.instant('ADMIN.PROJECTS.ARCHIVE_CONFIRMATION_DESCRIPTION'),
          sharedButtonClass: DIALOG_BUTTONS.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.PROJECTS.ARCHIVE_BUTTON'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return EMPTY;

          return this.projectService.setArchivedByProjectId(project.id, true);
        }),
      )
      .subscribe({
        next: () => {
          this.alertService.showSuccess('ADMIN.PROJECTS.SUCCESSFULLY_ARCHIVED');
          this.applyFilter(this.route.snapshot.queryParams, Date.now());
        },
        error: (err) => this.alertService.showError(errorMessage(err)),
      });
  }

  unArchiveDialog(project: AdminProject) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.PROJECTS.UNARCHIVE_CONFIRMATION'),
          description: this.translate.instant('ADMIN.PROJECTS.UNARCHIVE_CONFIRMATION_DESCRIPTION'),
          sharedButtonClass: DIALOG_BUTTONS.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.PROJECTS.UNARCHIVE_BUTTON'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return EMPTY;

          return this.projectService.setArchivedByProjectId(project.id, false);
        }),
      )
      .subscribe({
        next: () => {
          this.alertService.showSuccess('ADMIN.PROJECTS.SUCCESSFULLY_UNARCHIVED');
          this.applyFilter(this.route.snapshot.queryParams, Date.now());
        },
        error: (err) => this.alertService.showError(errorMessage(err)),
      });
  }
}
