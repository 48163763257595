import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../shared/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, EmployeeService } from 'src/app/shared/services';
import { AdminProjectDetail, IEmployeeSimple } from 'src/app/shared/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { errorMessage } from 'src/app/shared/utils';

@Component({
  selector: 'app-edit-projects',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit {
  project: AdminProjectDetail;
  employees: IEmployeeSimple[] = [];

  loading = true;

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl<string | null>(null),
    manager: new FormControl<IEmployeeSimple | null>(null, [Validators.required]),
    lead: new FormControl<IEmployeeSimple | null>(null),
    engineeringManager: new FormControl<IEmployeeSimple | null>(null, [Validators.required]),
  });

  constructor(
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.employeeService.getAllEmployeesSimple().subscribe((result) => {
      this.employees = result;
    });

    this.projectService.loadProjectById(this.route.snapshot.params.id).subscribe((result) => {
      this.project = result;
      this.form.setValue({
        name: result.name,
        description: result.description,
        manager: result.manager,
        lead: result.lead,
        engineeringManager: result.engineeringManager,
      });
      this.loading = false;
    });
  }

  handleSubmit() {
    if (this.form.invalid) return this.form.markAllAsTouched();

    this.projectService
      .updateProject({
        id: this.project.id,
        name: this.form.value.name,
        description: this.form.value.description,
        manager: this.form.value.manager.id,
        lead: this.form.value.lead?.id || null,
        engineeringManager: this.form.value.engineeringManager.id,
      })
      .subscribe({
        next: () => {
          this.alertService.showSuccess('ADMIN.LICENSES.SUCCESSFULLY_UPDATED');
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: (err) => this.alertService.showError(errorMessage(err)),
      });
  }
}
