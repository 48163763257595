<h2 class="section-title">
  {{ 'ADMIN.PROJECTS.PROJECTS' | translate }}
</h2>

<div class="actions">
  <mat-form-field color="accent" appearance="fill">
    <mat-label>{{ 'ADMIN.PROJECTS.PROJECT_SEARCH' | translate }}</mat-label>
    <input
      matInput
      type="text"
      (keyup.enter)="searchProject($event.target.value)"
      (blur)="searchProject($event.target.value)"
    />
  </mat-form-field>
  <div class="form-container" *ngIf="isAdmin">
    <mat-form-field color="accent" appearance="fill">
      <mat-label> {{ 'ADMIN.PROJECTS.PROJECT_NAME' | translate }}</mat-label>
      <input matInput [(ngModel)]="newProjectName" (keyup.enter)="createNewProject()" />
    </mat-form-field>

    <button
      type="button"
      mat-raised-button
      class="add-button"
      color="primary"
      (click)="createNewProject()"
    >
      {{ 'ADMIN.PROJECTS.ADD' | translate }}
    </button>
  </div>
</div>
<div class="relative">
  <div class="overlay" *ngIf="loading">
    <mat-spinner class="spinner-reset" [diameter]="64"></mat-spinner>
  </div>
  <table
    mat-table
    matSort
    [dataSource]="projects"
    (matSortChange)="
      applyFilter(
        $event.active && $event.direction && { field: $event.active, order: $event.direction }
      )
    "
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ADMIN.PROJECTS.EDIT_PROJECT.PROJECT_NAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let project">
        <a [routerLink]="['/dreamix/page/projects', project.id]" class="cell-link">
          {{ project.name }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="manager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ADMIN.PROJECTS.EDIT_PROJECT.PARTNERSHIP_MANAGER' | translate }}
      </th>
      <td mat-cell *matCellDef="let project">{{ project.manager || '-' }}</td>
    </ng-container>
    <ng-container matColumnDef="lead">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ADMIN.PROJECTS.EDIT_PROJECT.PROJECT_LEAD' | translate }}
      </th>
      <td mat-cell *matCellDef="let project">{{ project.lead || '-' }}</td>
    </ng-container>
    <ng-container matColumnDef="engineeringManager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ADMIN.PROJECTS.EDIT_PROJECT.PROJECT_EM' | translate }}
      </th>
      <td mat-cell *matCellDef="let project">{{ project.engineeringManager || '-' }}</td>
    </ng-container>
    <ng-container matColumnDef="employeesCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ADMIN.PROJECTS.EDIT_PROJECT.TEAM_SIZE' | translate }}
      </th>
      <td mat-cell *matCellDef="let project">{{ project.employeesCount }}</td>
    </ng-container>
    <ng-container matColumnDef="actions" *ngIf="isAdmin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let project">
        <mat-icon
          matTooltip="{{ 'SHARED.EDIT' | translate }}"
          [routerLink]="['/admin/projects/update', project.id]"
          class="icon user-edit"
          >edit</mat-icon
        >
        <mat-icon
          *ngIf="!project.archived"
          matTooltip="{{ 'SHARED.ARCHIVE' | translate }}"
          class="icon user-edit"
          (click)="archiveDialog(project)"
          >archive</mat-icon
        >
        <mat-icon
          *ngIf="project.archived"
          matTooltip="{{ 'SHARED.UNARCHIVE' | translate }}"
          class="icon user-edit"
          (click)="unArchiveDialog(project)"
          >unarchive</mat-icon
        >
        <mat-icon
          matTooltip="{{ 'SHARED.DELETE' | translate }}"
          class="icon delete"
          (click)="deleteDialog(project)"
          >cancel</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" *ngIf="isLead">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let project">
        <mat-icon
          matTooltip="{{ 'SHARED.EDIT' | translate }}"
          [routerLink]="['/lead/projects/update', project.id]"
          class="icon user-edit"
          >edit</mat-icon
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let project; columns: displayedColumns"
      [ngClass]="{ archived: project.archived }"
    ></tr>
  </table>
</div>

<mat-paginator
  showFirstLastButtons
  [pageSizeOptions]="[route.snapshot.queryParams.size || 10]"
  [pageSize]="route.snapshot.queryParams.size || 10"
  [length]="itemCount"
  [pageIndex]="route.snapshot.queryParams.page - 1 || 0"
  (page)="applyFilter({ page: $event.pageIndex + 1 })"
  *ngIf="itemCount > 10"
></mat-paginator>
