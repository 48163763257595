<div class="dialog-container">
  <div class="title-holder align-center">
    <h1 mat-dialog-title>
      {{ data.showSubmitForm ? 'Add Certificates' : 'Certificates' }}
    </h1>
    <button mat-mini-fab class="close-button" color="primary" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content *ngIf="data.showSubmitForm">
    <form [formGroup]="form" (ngSubmit)="submitHandler()">
      <div>
        <app-multi-select-field
          [all]="allCertificates$ | async"
          formControlName="certificates"
          [label]="'ADMIN.EMPLOYEES.FILTERS.CERTIFICATES_LABEL' | translate"
          [placeholder]="'ADMIN.EMPLOYEES.FILTERS.CERTIFICATES_PLACEHOLDER' | translate"
          [optionsToExclude]="employeeCertificates"
        ></app-multi-select-field>
        <mat-error *ngIf="form.controls.certificates.invalid && form.controls.certificates.touched">
          {{ 'SHARED.VALIDATION.REQUIRED' | translate }}.
        </mat-error>
      </div>

      <div class="inputs-holder">
        <mat-form-field>
          <mat-label>Valid From</mat-label>
          <input matInput [matDatepicker]="validFrom" formControlName="validFrom" />
          <mat-datepicker-toggle matIconSuffix [for]="validFrom"></mat-datepicker-toggle>
          <mat-datepicker #validFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Valid To</mat-label>
          <input matInput [matDatepicker]="validTo" formControlName="validTo" />
          <mat-datepicker-toggle matIconSuffix [for]="validTo"></mat-datepicker-toggle>
          <mat-datepicker #validTo></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex-end">
        <button mat-raised-button color="primary" type="submit">Add</button>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-content class="table-holder">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="certificateName"
      matSortDirection="asc"
      matSortDisableClear
    >
      <ng-container matColumnDef="certificateName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cert Name</th>
        <td mat-cell *matCellDef="let certificate">{{ certificate.certificateName }}</td>
      </ng-container>

      <ng-container matColumnDef="validFrom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Valid From</th>
        <td mat-cell *matCellDef="let certificate">{{ certificate.validFrom }}</td>
      </ng-container>

      <ng-container matColumnDef="validTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Valid To</th>
        <td mat-cell mat-cell *matCellDef="let certificate">{{ certificate.validTo }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let certificate">
          <div *ngIf="data.showSubmitForm">
            <mat-icon
              matTooltip="{{ 'SHARED.DELETE' | translate }}"
              (click)="openDialog(certificate)"
              class="delete-icon"
            >
              cancel
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">
          {{ 'USER.REIMBURSEMENT_REQUESTS.NO_DATA' | translate }}
        </td>
      </tr>
    </table>
  </mat-dialog-content>
</div>
