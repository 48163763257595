import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  CertificateItem,
  EmployeeCertificate,
  IAllCertificates,
} from 'src/app/shared/models/employee-certificates.model';
import { EmployeeCertificatesService } from 'src/app/shared/services/employee-certificates.service';
import { dateToStringWithoutTimezone } from 'src/app/shared/services/helpers/date.helper';
import { errorMessage } from 'src/app/shared/utils';
import { DIALOG_BUTTONS } from '../../../shared/constants';
import { DialogComponent } from '../../../shared/dialogs';
import { AlertService } from '../../../shared/services';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-certificates-dialog',
  templateUrl: './certificates-dialog.component.html',
  styleUrls: ['./certificates-dialog.component.scss'],
})
export class CertificatesDialogComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  allCertificates$: Observable<IAllCertificates[]>;
  employeeCertificates: EmployeeCertificate[] = [];

  revalidate$ = new Subject<void>();

  displayedColumns = ['certificateName', 'validFrom', 'validTo', 'actions'];

  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource<EmployeeCertificate>([]);
  
  constructor(
    private dialog: MatDialog,
    private employeeCertificatesService: EmployeeCertificatesService,
    private readonly formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<CertificatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { employeeId: number; showSubmitForm: boolean },
  ) {}

  private initForm() {
    this.form = this.formBuilder.group({
      certificates: [null, [Validators.required]],
      validFrom: [null],
      validTo: [null],
    });
  }

  ngOnInit() {
    this.initForm();
    this.allCertificates$ = this.employeeCertificatesService.getAllCertificates();

    this.revalidate$
      .pipe(
        switchMap(() =>
          this.employeeCertificatesService.getEmployeeCertificates(this.data.employeeId),
        ),
      )
      .subscribe((data) => {
        // map certificateId to id, because of the app-multi-select-field component
        this.employeeCertificates = data
          .map((cert: EmployeeCertificate) => ({
            ...cert,
            id: cert.certificateId,
          }));
        this.dataSource.data = this.employeeCertificates;
      });

    this.revalidate$.next();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  removeCertificate(certificateId: number): void {
    this.employeeCertificatesService
      .deleteEmployeeCertificate(this.data.employeeId, certificateId)
      .subscribe({
        next: () => {
          this.revalidate$.next();
        },
        error: (err) => {
          this.alertService.showError(errorMessage(err));
        },
      });
  }

  submitHandler() {
    this.form.markAllAsTouched();

    if (!this.form.valid) return;

    combineLatest(
      this.form.value.certificates?.map((cert: CertificateItem) => {
        return this.employeeCertificatesService.createEmployeeCertificate({
          employeeId: this.data.employeeId,
          certificateId: cert.id,
          validTo: dateToStringWithoutTimezone(this.form.value.validTo),
          validFrom: dateToStringWithoutTimezone(this.form.value.validFrom),
        });
      }),
    ).subscribe({
      complete: () => {
        this.alertService.showSuccess('Certificate Added Successfully');

        this.form.reset();
        this.form.controls.certificates.setErrors(null);

        this.revalidate$.next();
      },
      error: (err) => {
        this.alertService.showError(errorMessage(err));
      },
    });
  }

  openDialog(certificate: EmployeeCertificate) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE_CONFIRMATION'),
          description: `Do you want to delete "${certificate.certificateName}"?`,
          sharedButtonClass: DIALOG_BUTTONS.deleteButton,
          sharedButtonText: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result) return EMPTY;

          return this.employeeCertificatesService.deleteEmployeeCertificate(
            this.data.employeeId,
            certificate.certificateId,
          );
        }),
      )
      .subscribe({
        next: () => {
          this.alertService.showSuccess('Certificate Deleted Successfully');
          this.revalidate$.next();
        },
        error: (err) => {
          this.alertService.showError(errorMessage(err));
        },
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  displayFn(cert?: CertificateItem) {
    return cert?.name ?? '';
  }
}
